import useSettingsStore from './settings'
import useRouteStore from './route'
import useMenuStore from './menu'
import router from '@/router'
import type { loginParamsType } from '@/api/modules/user'
import apiUser from '@/api/modules/user'

const useUserStore = defineStore(
  // 唯一ID
  'user',
  () => {
    const settingsStore = useSettingsStore()
    const routeStore = useRouteStore()
    const menuStore = useMenuStore()

    const userId = ref(localStorage.userId ?? '')
    const account = ref(localStorage.account ?? '')
    const token = ref(localStorage.token ?? '')
    const avatar = ref(localStorage.avatar ?? '')
    const permissions = ref<string[]>([])
    const isLogin = computed(() => {
      if (token.value) {
        return true
      }
      return false
    })

    // 登录
    async function login(data: loginParamsType) {
      const res = await apiUser.login(data)
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('SET_EXPIRES_IN', res.data.expiresIn)
      token.value = res.data.accessToken
      GetInfo()
    }

    async function GetInfo() {
      return new Promise((resolve, reject) => {
        apiUser.getInfo().then((res) => {
          const user = res.user
          account.value = user.userName
          localStorage.setItem('account', user.userName)
          avatar.value = user.avatar
          localStorage.setItem('avatar', user.avatar)
          userId.value = user.userId
          localStorage.setItem('userId', String(user.userId))
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    }
    // 登出
    async function logout(redirect = router.currentRoute.value.fullPath) {
      localStorage.removeItem('account')
      localStorage.removeItem('token')
      localStorage.removeItem('avatar')
      account.value = ''
      token.value = ''
      avatar.value = ''
      permissions.value = []
      routeStore.removeRoutes()
      menuStore.setActived(0)
      router.push({
        name: 'login',
        query: {
          ...(router.currentRoute.value.path !== settingsStore.settings.home.fullPath && router.currentRoute.value.name !== 'login' && { redirect }),
        },
      })
    }
    return {
      account,
      token,
      avatar,
      permissions,
      isLogin,
      login,
      logout,
    }
  },
)

export default useUserStore
